import { regexEmail, regexPhoneNr } from '@pe-libs/react-components/build/lib/helper';
import yup from '#schema/locale';

export const step2 = yup.object({
  fullName: yup
    .string()
    .trim()
    .required(),
  emailAddress: yup
    .string()
    .trim()
    .matches(regexEmail)
    .required(),
  phoneNumber: yup
    .string()
    .matches(regexPhoneNr)
    .trim(),
  termsOfUse: yup.boolean().oneOf([true]),
});
