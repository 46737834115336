import { request } from '@pe-libs/react-components/build/lib/store/api';
import { NextPageContext } from 'next';
import { StateType } from '../../../types';
import { GetIndustriesSchema } from '../schemas/createLead';

const industriesAPI = (state: StateType) => {
  const locale = state.app.settings?.locale ?? 'de-DE';

  return {
    getIndustries: async ({ contextRequest }: { contextRequest?: NextPageContext['req'] }) =>
      request({
        locale,
        contextRequest,
        path: '/v2/industries?existsOrAllParent=all',
        method: 'GET',
        schema: GetIndustriesSchema,
        withAuth: false,
      }),
  };
};

export default industriesAPI;
