import { request } from '@pe-libs/react-components/build/lib/store/api';
import { NextPageContext } from 'next';
import { StateType } from '../../../types';
import { globalSystemInfoSchema } from '../schemas/globalSystemInfo';

const systemInformationAPI = (state: StateType) => {
  const locale = state.app.settings?.locale ?? 'de-DE';

  return {
    getGlobalSystemInfo: async ({ contextRequest }: { contextRequest?: NextPageContext['req'] }) =>
      request({
        locale,
        contextRequest,
        path: '/v2/system_information/global',
        method: 'GET',
        schema: globalSystemInfoSchema,
        withAuth: false,
      }),
  };
};

export default systemInformationAPI;
