import { createReducer } from '@reduxjs/toolkit';
import { globalSystemInfoRequested } from '../actions/globalSystemInfo';
import { StateGlobalSystemInfoType } from './types';

const initialState = {
  loading: false,
  data: {
    attributes: {
      id: 'global',
      externalRatingCrawlerCount: 0,
      industryTotalCount: 0,
      profileTotalCount: 0,
    },
  },
};

const globalSystemInfo = createReducer<StateGlobalSystemInfoType>(initialState, (builder) =>
  builder
    .addCase(globalSystemInfoRequested.fulfilled, (state, { payload }) => {
      state.loading = false;

      if (payload.data?.attributes) {
        state.data.attributes = payload.data.attributes;
      }
    })
    .addCase(globalSystemInfoRequested.rejected, (state) => {
      state.loading = false;
    })
    .addCase(globalSystemInfoRequested.pending, (state) => {
      state.loading = true;
    }),
);

export default globalSystemInfo;
