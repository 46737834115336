import { getPublicEnvironmentValue } from '@pe-libs/react-components';
import { request } from '@pe-libs/react-components/build/lib/store/api';
import { NextPageContext } from 'next';
import { selectIsEmployerHost } from '#modules/app/selectors';
import { StateType } from '../../../types';
import { affiliateDataSchema } from '../schemas/profile';

const profileAPI = (state: StateType) => {
  const locale = state.app.settings?.locale ?? 'de-DE';

  const isEmployerHost = selectIsEmployerHost(state);

  const wwwHost = getPublicEnvironmentValue('WWW_HOST', isEmployerHost);

  return {
    getAffiliateUserData: async ({ profileId }: { contextRequest?: NextPageContext['req']; profileId: string }) =>
      request({
        locale,
        path: `${wwwHost}/restapi/v1/profiles/${profileId}/affiliate-register`,
        method: 'GET',
        schema: affiliateDataSchema,
      }),
  };
};

export default profileAPI;
