import * as yup from 'yup';
import messages from './messages';

yup.setLocale({
  mixed: {
    required: ({ path }) => ({ key: messages.mixedRequired, values: { label: path as string } }),
    oneOf: ({ values }: { values: string }) => {
      if (values === 'true') {
        return { key: messages.mixedRequired, values: { label: values } };
      }

      return { key: messages.mixedOneOf, values: { label: values } };
    },
  },
  string: {
    min: ({ path, min }) => ({ key: messages.stringMin, values: { label: path, min } }),
    max: ({ path, max }) => ({ key: messages.stringMax, values: { label: path, max } }),
    matches: ({ path }) => ({ key: messages.stringMatches, values: { label: path } }),
    email: ({ path }) => ({ key: messages.stringEmail, values: { label: path } }),
    url: ({ path }) => ({ key: messages.stringUrl, values: { label: path } }),
  },
});

yup.addMethod(yup.string, 'minSlug', function minSlug(number: number) {
  return this.test(
    'minSlug',
    ({ path }: { path: string }) => ({ key: messages.stringMin, values: { label: path, min: number } }),
    function (value?: string) {
      if (!value) {
        return true;
      }

      const isInvalid =
        value
          .replace(/—/g, '')
          .replace(/–/g, '')
          .replace(/\p{Pd}/gu, '')
          .replace(/[-]+/gu, '').length < number;

      if (isInvalid) {
        throw this.createError({
          path: this.path,
        });
      }

      return true;
    },
  );
});

export default yup;
