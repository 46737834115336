/* eslint-disable import/no-unused-modules */
import { Action, configureStore, EnhancedStore } from '@reduxjs/toolkit';
import { createWrapper, HYDRATE } from 'next-redux-wrapper';
import { ThunkAction } from 'redux-thunk';
import combinedReducer from './reducer';
import { StateType } from './types';

let store: EnhancedStore<StateType, Action> | undefined;

const initialState = {};

const reducer = (state: StateType | undefined, action: Action) => {
  if (action.type === HYDRATE) {
    const nextState: StateType = {
      ...state,
      ...(action as { type: string; payload: StateType }).payload,
      app: {
        ...state?.app,
        globalSystemInfo: {
          loading: state?.app?.globalSystemInfo.loading ?? false,
          data:
            state?.app?.globalSystemInfo?.data?.attributes?.industryTotalCount &&
            state?.app?.globalSystemInfo?.data?.attributes?.industryTotalCount > 0
              ? state?.app?.globalSystemInfo.data
              : (action as { type: string; payload: StateType }).payload.app.globalSystemInfo.data,
        },
        settings: {
          ...state?.app?.settings,
          ...(action as { type: string; payload: StateType }).payload?.app?.settings,
        },
      },
    };

    return nextState;
  }

  return combinedReducer(state, action);
};

function initStore(preloadedState = initialState) {
  return configureStore({
    reducer,
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActionPaths: [
            'payload.ajax.error',
            'payload.ajax.success',
            'payload.ajax.schema',
            'meta.arg.contextRequest',
          ],
        },
      }),
    devTools: process.env.NODE_ENV !== 'production',
  });
}

const initializeStore = (preloadedState?: Record<string, unknown>) => {
  let temporaryStore = store ?? initStore(preloadedState);

  if (preloadedState && store) {
    temporaryStore = initStore({
      ...store.getState(),
      ...preloadedState,
    });

    store = undefined;
  }

  if (typeof window === 'undefined') {
    return temporaryStore;
  }

  if (!store) {
    store = temporaryStore;
  }

  return temporaryStore;
};

export type AppStore = ReturnType<typeof makeStore>;
export type AppState = ReturnType<AppStore['getState']>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, AppState, unknown, Action>;

const makeStore = () => initStore();

export const wrapper = createWrapper<AppStore>(makeStore, {
  debug: false,
});

export default initializeStore;
