import { createAsyncThunk } from '@reduxjs/toolkit';
import { StateType } from '../../../types';
import formAPI from '../api/form';

type ReferralRemaxFormType = {
  type: string;
  profile: string;
  consulting: boolean;
  others: boolean;
  comment: string;
  firstName: string;
  lastName: string;
  emailAddress: string;
  phone: string;
  invitationUrl: string;
  reviewId: string;
};

export const referralFormRemaxRequested = createAsyncThunk(
  'LANDING_REFERRAL_REMAX/FORM_REQUESTED',
  async (data: ReferralRemaxFormType, store) => {
    const state = store.getState() as StateType;
    const response = await formAPI(state).getForm(data);

    return response;
  },
);
