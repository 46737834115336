import yup from '#schema/locale';

export const step1 = yup.object({
  industry: yup
    .string()
    .trim()
    .required(),
  location: yup
    .string()
    .trim()
    .required(),
  dueDate: yup
    .string()
    .required()
    .trim(),
  message: yup
    .string()
    .trim()
    .required(),
});
