import { createReducer } from '@reduxjs/toolkit';
import { loadingChanged } from '../actions/expertCompass';
import { externalRatingCrawlerCountRequested } from '../actions/externalRating';
import { StateGlobalType } from './types';

const initialState = {
  loading: false,
  customerReviewsInMillions: 15,
  data: [],
};

const globalReducer = createReducer<StateGlobalType>(initialState, (builder) =>
  builder
    .addCase(loadingChanged, (state, { payload }) => {
      state.loading = payload;
    })
    .addCase(externalRatingCrawlerCountRequested.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data = payload.data;
    })
    .addCase(externalRatingCrawlerCountRequested.rejected, (state) => {
      state.loading = false;
    })
    .addCase(externalRatingCrawlerCountRequested.pending, (state) => {
      state.loading = true;
    }),
);

export default globalReducer;
