import { FontType } from '@pe-libs/react-components/build/lib/types';
import titillium, { name } from './titillium';

/**
 * @see https://developer.mozilla.org/en-US/docs/Web/CSS/font-weight
 */

const fonts: FontType[] = titillium;
const fontData = { name, fonts };

export default fontData;
