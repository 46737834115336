import { request } from '@pe-libs/react-components/build/lib/store/api';
import { NextPageContext } from 'next';
import { InferType } from 'yup';
import { StateType } from '../../../types';
import { CreateLeadResponseSchema, CreateLeadSchema } from '../schemas/createLead';

const customerRequestsAPI = (state: StateType) => {
  const locale = state.app.settings?.locale ?? 'de-DE';

  return {
    createLead: async ({
      contextRequest,
      page1,
      page2,
    }: {
      contextRequest?: NextPageContext['req'];
      page1: InferType<typeof CreateLeadSchema>['page1'];
      page2: InferType<typeof CreateLeadSchema>['page2'];
    }) =>
      request({
        locale,
        contextRequest,
        path: '/v2/customer_requests',
        method: 'POST',
        body: { ...page1, ...page2 },
        schema: CreateLeadResponseSchema,
      }),
  };
};

export default customerRequestsAPI;
