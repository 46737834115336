import { createAsyncThunk } from '@reduxjs/toolkit';
import { NextPageContext } from 'next';
import { StateType } from '../../../types';
import formAPI from '../api/form';

type FormData = {
  type: string;
  profile: string;
  consulting?: boolean;
  newsletter?: boolean;
  others?: boolean;
  comment: string;
  firstName: string;
  lastName: string;
  emailAddress: string;
  invitationUrl: string;
  reviewId: string | null;
  phoneNumber?: string;
  birthday?: string;
  contextRequest?: NextPageContext['req'];
};

export const referralFormRequested = createAsyncThunk('expertDirectory/getForm', async (data: FormData, store) => {
  const state = store.getState() as StateType;
  const response = await formAPI(state).getForm(data);

  return response;
});
