import { request } from '@pe-libs/react-components/build/lib/store/api';
import { NextPageContext } from 'next';
import { getUTMParameters } from '#helpers/utm';
import { StateType } from '../../../types';
import { userExtendedSchema, userSchema } from '../schemas/onboarding';

const usersAPI = (state: StateType) => {
  const locale = state.app.settings?.locale ?? 'de-DE';
  const UTMparams = getUTMParameters();

  return {
    createUser: async ({
      contextRequest,
      emailAddress,
      isResend,
    }: {
      contextRequest?: NextPageContext['req'];
      emailAddress: string;
      isResend?: boolean;
    }) =>
      request({
        locale,
        contextRequest,
        path: `/v2/users/actions/register_with_email_address${UTMparams ? `?${UTMparams}` : ''}`,
        method: 'POST',
        body: { emailAddress, isResend },
        schema: userExtendedSchema,
        credentials: 'include',
      }),
    getRegistrationDataFallback: async ({
      contextRequest,
      confirmationCode,
    }: {
      contextRequest?: NextPageContext['req'];
      confirmationCode: string;
    }) =>
      request({
        locale,
        contextRequest,
        path: `/v2/users/actions/registration/${confirmationCode}`,
        method: 'GET',
        schema: userSchema,
      }),
  };
};

export default usersAPI;
