import { AnyAction } from '@pe-libs/react-components/build/lib/types';
import { combineReducers } from 'redux';
import app from '#modules/app/reducers';
import auth from '#modules/auth/reducers';
import defaultLandingReferral from '#modules/defaultLandingReferral/reducers';
import expertDirectory from '#modules/expertDirectory/reducers';
import landingReferral from '#modules/landingReferral/reducers';
import { StateType } from './types';

const reducer = combineReducers<StateType, AnyAction>({
  app,
  auth,
  expertDirectory,
  landingReferral,
  defaultLandingReferral,
});

export default reducer;
