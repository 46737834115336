import { FontType } from '@pe-libs/react-components/build/lib/types';
import titilliumBoldEot from './titilliumweb-bold-webfont.eot';
import titilliumBoldTtf from './titilliumweb-bold-webfont.ttf';
import titilliumBoldWoff from './titilliumweb-bold-webfont.woff';
import titilliumBoldWoff2 from './titilliumweb-bold-webfont.woff2';
import titilliumExtralightEot from './titilliumweb-extralight-webfont.eot';
import titilliumExtralightTtf from './titilliumweb-extralight-webfont.ttf';
import titilliumExtralightWoff from './titilliumweb-extralight-webfont.woff';
import titilliumExtralightWoff2 from './titilliumweb-extralight-webfont.woff2';
import titilliumLightEot from './titilliumweb-light-webfont.eot';
import titilliumLightTtf from './titilliumweb-light-webfont.ttf';
import titilliumLightWoff from './titilliumweb-light-webfont.woff';
import titilliumLightWoff2 from './titilliumweb-light-webfont.woff2';
import titilliumRegularEot from './titilliumweb-regular-webfont.eot';
import titilliumRegularTtf from './titilliumweb-regular-webfont.ttf';
import titilliumRegularWoff from './titilliumweb-regular-webfont.woff';
import titilliumRegularWoff2 from './titilliumweb-regular-webfont.woff2';
import titilliumSemiboldEot from './titilliumweb-semibold-webfont.eot';
import titilliumSemiboldTtf from './titilliumweb-semibold-webfont.ttf';
import titilliumSemiboldWoff from './titilliumweb-semibold-webfont.woff';
import titilliumSemiboldWoff2 from './titilliumweb-semibold-webfont.woff2';

export const name = 'Titillium';
export const fonts: FontType[] = [
  {
    fontFamily: name,
    fontStyle: 'normal',
    fontWeight: 200,
    fontDisplay: 'swap',
    src: [
      `url(${titilliumExtralightWoff2}) format('woff2')`,
      `url(${titilliumExtralightWoff}) format('woff')`,
      `url(${titilliumExtralightEot}) format('embedded-opentype')`,
      `url(${titilliumExtralightTtf}) format('truetype')`,
    ].join(', '),
  },
  {
    fontFamily: name,
    fontStyle: 'normal',
    fontWeight: 300,
    fontDisplay: 'swap',
    src: [
      `url(${titilliumLightWoff2}) format('woff2')`,
      `url(${titilliumLightWoff}) format('woff')`,
      `url(${titilliumLightEot}) format('embedded-opentype')`,
      `url(${titilliumLightTtf}) format('truetype')`,
    ].join(', '),
  },
  {
    fontFamily: name,
    fontStyle: 'normal',
    fontWeight: 400,
    fontDisplay: 'swap',
    src: [
      `url(${titilliumRegularWoff2}) format('woff2')`,
      `url(${titilliumRegularWoff}) format('woff')`,
      `url(${titilliumRegularEot}) format('embedded-opentype')`,
      `url(${titilliumRegularTtf}) format('truetype')`,
    ].join(', '),
  },
  {
    fontFamily: name,
    fontStyle: 'normal',
    fontWeight: 600,
    fontDisplay: 'swap',
    src: [
      `url(${titilliumSemiboldWoff2}) format('woff2')`,
      `url(${titilliumSemiboldWoff}) format('woff')`,
      `url(${titilliumSemiboldEot}) format('embedded-opentype')`,
      `url(${titilliumSemiboldTtf}) format('truetype')`,
    ].join(', '),
  },
  {
    fontFamily: name,
    fontStyle: 'normal',
    fontWeight: 700,
    fontDisplay: 'swap',
    src: [
      `url(${titilliumBoldWoff2}) format('woff2')`,
      `url(${titilliumBoldWoff}) format('woff')`,
      `url(${titilliumBoldEot}) format('embedded-opentype')`,
      `url(${titilliumBoldTtf}) format('truetype')`,
    ].join(', '),
  },
];

export default fonts;
