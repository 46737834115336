import { defineMessages } from 'react-intl';

const messages = defineMessages({
  mixedRequired: { id: 'lib-schema-mixed-required' },
  mixedOneOf: { id: 'lib-schema-mixed-oneOf' },
  stringMin: { id: 'lib-schema-string-min' },
  stringMax: { id: 'lib-schema-string-max' },
  stringMatches: { id: 'lib-schema-string-matches' },
  stringEmail: { id: 'lib-schema-string-email' },
  stringUrl: { id: 'lib-schema-string-url' },
});

export default messages;
