import { regexEmail, regexPhoneNr } from '@pe-libs/react-components/build/lib/helper';
import yup from '#schema/locale';

export const validationSchema = yup.object({
  consulting: yup.boolean(),
  newsletter: yup.boolean(),
  others: yup.boolean(),
  comment: yup.string().max(100),
  name: yup
    .string()
    .required()
    .min(3),
  surname: yup
    .string()
    .required()
    .min(3),
  email: yup
    .string()
    .required()
    .matches(regexEmail),
  telephone: yup.string().matches(regexPhoneNr),
});

export const referralPostSchema = yup.object({
  consulting: yup.boolean(),
  newsletter: yup.boolean(),
  others: yup.boolean(),
  comment: yup.string(),
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  emailAddress: yup.string().required(),
  phoneNumber: yup.string(),
  reviewId: yup.string().required(),
  profile: yup.string(),
  type: yup.string(),
  invitationUrl: yup.string(),
});
