import yup from '#schema/locale';

export const searchSchema = yup.object({
  search: yup
    .string()
    .min(2)
    .required(),
  location: yup.string().min(3),
  radius: yup.string(),
  meta: yup.object({
    count: yup.number(),
    page: yup.number(),
    totalPages: yup.number(),
  }),
});

export const singleResultSchema = yup.object({
  profile: yup.object({
    title: yup.string(),
    activities: yup.string(),
    description: yup.string(),
    avatar: yup.string(),
    createdAt: yup.string(),
    establishedAt: yup.string(),
    url: yup.string(),
    email: yup.string().notRequired(),
    offer: yup.array(yup.string()),
    verified: yup.bool(),
    scenario: yup.string().oneOf(['a', 'b', 'c']),
  }),
  industries: yup.array(yup.string()),
  geoLocation: yup.object({
    lat: yup.number(),
    long: yup.number(),
  }),
  address: yup.object({
    city: yup.string(),
    countryCode: yup.string(),
    state: yup.string(),
    street: yup.string(),
    zipCode: yup.string(),
    phone: yup.string(),
  }),
  ratings: yup.object({
    aggregatedProfileCount: yup.number(),
    averageRatingScore: yup.number(),
    lastRatedAt: yup.string(),
    totalRatingCount: yup.number(),
    totalRatingCountWithUnpublished: yup.number(),
    totalReviewCount: yup.number(),
    externalRatingServicesCount: yup.number(),
    awards: yup.object({
      topService: yup.bool(),
      topRecommendation: yup.bool(),
      customersChoice: yup.bool(),
    }),
  }),

  socialProfiles: yup.array(yup.string()),
  websites: yup.array(yup.string()),
  websitesWithLabel: yup.array(
    yup.object({
      label: yup.string(),
      url: yup.string(),
    }),
  ),
});

const autocompleteIndustriesSingleResult = yup.object({
  id: yup.string(),
  key: yup.string(),
  name: yup.object({
    de_DE: yup.string(),
    en_US: yup.string(),
  }),
});

export const searchResultSchema = yup.object({
  meta: yup.object({
    count: yup.number(),
    page: yup.number(),
    totalPages: yup.number(),
  }),
  links: yup.object({
    self: yup.string(),
    first: yup.string(),
    prev: yup.string(),
    next: yup.string(),
    last: yup.string(),
  }),
  data: yup.array(singleResultSchema),
  context: yup
    .object({
      industry: autocompleteIndustriesSingleResult.notRequired(),
      location: yup
        .object({
          id: yup.string(),
          name: yup.string(),
        })
        .notRequired(),
    })
    .notRequired(),
});

export const autocompleteIndustriesResultSchema = yup.object({
  meta: yup.object({ count: yup.number() }).nullable(),
  data: yup.array(autocompleteIndustriesSingleResult),
});

const autocompleteLocationsSingleResult = yup.object({
  id: yup.string(),
  key: yup.string(),
  name: yup.string(),
});

export const autocompleteLocationsResultSchema = yup.object({
  meta: yup.object({ count: yup.number() }).nullable(),
  data: yup.array(autocompleteLocationsSingleResult),
});

const topSearchesSingleResult = yup.object({
  location: yup.string(),
  industry: yup.object({
    id: yup.string(),
    key: yup.string(),
    name: yup.object({
      de_DE: yup.string(),
      en_US: yup.string(),
    }),
  }),
});

export const topSearchesSchema = yup.object({
  data: yup.array(topSearchesSingleResult),
});
