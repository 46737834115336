import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { StateType } from '../../../types';
import usersAPI from '../api/users';

export const userRegistered = createAsyncThunk(
  'auth/register/user_register_requested',
  async ({ email, isResend }: { email: string; isResend?: boolean }, store) => {
    const state = store.getState() as StateType;

    return usersAPI(state).createUser({ emailAddress: email, isResend });
  },
);

export const pageChanged = createAction('AUTH/REGISTER/PAGE_CHANGED', (payload: string) => ({ payload }));
