import { createAsyncThunk } from '@reduxjs/toolkit';
import { StateType } from '../../../types';
import formAPI from '../api/form';

type ReferralVonPollFormType = {
  type: string;
  profile: string;
  consulting: boolean;
  newsletter: boolean;
  firstName: string;
  lastName: string;
  emailAddress: string;
  phone: string;
  invitationUrl: string;
  reviewId: string;
};

export const referralFormVonPollRequested = createAsyncThunk(
  'LANDING_REFERRAL_VON_POLL/FORM_REQUESTED',
  async (data: ReferralVonPollFormType, store) => {
    const state = store.getState() as StateType;
    const response = await formAPI(state).getForm(data);

    return response;
  },
);
