import yup from '#schema/locale';

export const industriesSchema = yup.object({
  data: yup
    .array(
      yup.object({
        id: yup.string().required(),
        attributes: yup
          .object({
            _id: yup.string().required(),
            name: yup.string().required(),
            title: yup.string().nullable(),
            aliases: yup
              .array()
              .of(yup.string())
              .nullable(),
          })
          .required(),
      }),
    )
    .required(),
});

export const trinityResponseSchema = yup.object({
  data: yup.object({
    attributes: yup.object({
      accessToken: yup.string(),
      refreshToken: yup.string(),
      userId: yup.string().nullable(),
      accountId: yup.string().nullable(),
      profileId: yup.string().nullable(),
      status: yup.string().required(),
    }),
  }),
});

export const userSchema = yup.object({
  data: yup.object({
    attributes: yup.object({
      firstName: yup.string().nullable(),
      lastName: yup.string().nullable(),
      emailAddress: yup.string().nullable(),
      externalAuthService: yup.string().nullable(),
      type: yup.string().nullable(),
    }),
  }),
});

export const userExtendedSchema = userSchema.concat(
  yup.object().shape({
    isResend: yup.boolean(),
  }),
);
