/* eslint-disable camelcase */
import { createReducer } from '@reduxjs/toolkit';
import { setSearchRadius } from '../actions';
import { RadiusValues } from '../components/SearchSection/data';
import { StateSearchRadiusType } from './types';

const initialState = {
  searchRadius: RadiusValues['Ganzer Ort'],
};

const searchRadius = createReducer<StateSearchRadiusType>(initialState, (builder) =>
  builder.addCase(setSearchRadius, (state, action) => {
    state.searchRadius = action.payload;
  }),
);

export default searchRadius;
