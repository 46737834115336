import { request } from '@pe-libs/react-components/build/lib/store/api';
import { NextPageContext } from 'next';
import { getUTMParameters } from '#helpers/utm';
import yup from '#schema/locale';
import { StateType } from '../../../types';
import { trinityResponseSchema } from '../schemas/onboarding';

const accountsAPI = (state: StateType) => {
  const locale = state.app?.settings?.locale ?? 'de-DE';
  const UTMparams = getUTMParameters();

  return {
    getOnboardingFinished: async ({
      contextRequest,
      data,
    }: {
      contextRequest?: NextPageContext['req'];
      data: yup.InferType<typeof trinityResponseSchema> | { confirmationCode: string };
    }) =>
      request({
        locale,
        contextRequest,
        path: `/v2/accounts/actions/create_trinity${UTMparams ? `?${UTMparams}` : ''}`,
        method: 'POST',
        body: data,
        schema: trinityResponseSchema,
      }),
  };
};

export default accountsAPI;
