import { createSelector } from 'reselect';
import { StateType } from '../../../types';

const getSettings = createSelector(
  (state: StateType) => state.app,
  (app) => app.settings,
);

export const getUserLocale = createSelector(getSettings, (settings) => settings?.locale);

export const getIsCookiesAccepted = createSelector(getSettings, (settings) => settings?.isCookiesAccepted);

export const withDarkMode = createSelector(getSettings, (settings) => settings?.withDarkMode);

export const selectIsEmployerHost = createSelector(getSettings, (settings) => Boolean(settings?.isEmployerHost));
