import { request } from '@pe-libs/react-components/build/lib/store/api';
import { NextPageContext } from 'next';
import { StateType } from '../../../types';
import { userSchema } from '../schemas/onboarding';

const actionsAPI = (state: StateType) => {
  const locale = state.app?.settings?.locale ?? 'de-DE';

  return {
    getRegistrationData: async ({
      contextRequest,
      confirmationCode,
    }: {
      contextRequest?: NextPageContext['req'];
      confirmationCode: string;
    }) =>
      request({
        locale,
        contextRequest,
        path: `/v2/actions/registration/${confirmationCode}`,
        method: 'GET',
        schema: userSchema,
      }),
  };
};

export default actionsAPI;
