import { createAsyncThunk } from '@reduxjs/toolkit';
import { NextPageContext } from 'next';
import { StateType } from '../../../types';
import externalRatingAPI from '../api/externalRating';

export const externalRatingCrawlerCountRequested = createAsyncThunk(
  'EXPERT_DIRECTORY/EXTERNAL_RATING_CRAWLER_COUNT_REQUESTED',
  async (data: { contextRequest?: NextPageContext['req'] } | undefined, store) => {
    const state = store.getState() as StateType;

    return externalRatingAPI(state).getExternalRatingCrawlerCount({ contextRequest: data?.contextRequest });
  },
);
