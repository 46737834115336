import { createReducer } from '@reduxjs/toolkit';
import { getAffiliateUserData } from '../actions/profile';
import { StateAuthProfileTypes } from './types';

const initialState = {
  imageUrl: '',
  name: '',
};
const registerReducer = createReducer<StateAuthProfileTypes>(initialState, (builder) =>
  builder.addCase(getAffiliateUserData.fulfilled, (state, action) => {
    state.imageUrl = action.payload.profile.imageUrl;
    state.name = action.payload.profile.name;
  }),
);

export default registerReducer;
