/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';
import { profilePublicDetailsRequested } from '../actions';
import { ProfilePublicDataType } from './types';

const initialState: ProfilePublicDataType = {
  loading: false,
  data: {
    attributes: {
      id: '',
      title: '',
      description: '',
      offers: '',
      profileUrl: '',
      avatarUrlFull: '',
      avatarUrlMedium: '',
      headerUrlFull: '',
      headerUrlMedium: '',
    },
  },
};

const profileReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(profilePublicDetailsRequested.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload.data;
    })
    .addCase(profilePublicDetailsRequested.rejected, (state) => {
      state.loading = false;
    })
    .addCase(profilePublicDetailsRequested.pending, (state) => {
      state.loading = true;
    });
});

export default profileReducer;
