import { createReducer } from '@reduxjs/toolkit';
import { userRegistered, pageChanged } from '../actions/register';
import { StateAuthRegisterTypes } from './types';

const initialState = {
  currentPage: 'register',
  loading: false,
};
const registerReducer = createReducer<StateAuthRegisterTypes>(initialState, (builder) =>
  builder
    .addCase(userRegistered.pending, (state) => {
      state.loading = true;
    })
    .addCase(userRegistered.fulfilled, (state, action) => {
      state.loading = false;
      state.currentPage = action.payload.isResend ? 'confirmationResent' : 'confirmationSent';
    })
    .addCase(userRegistered.rejected, (state) => {
      state.loading = false;
    })
    .addCase(pageChanged, (state, { payload }) => {
      state.currentPage = payload;
    }),
);

export default registerReducer;
