import { combineReducers } from 'redux';
import autocomplete from './autocomplete';
import createLead from './createLead';
import global from './global';
import industries from './industries';
import searchRadius from './searchRadius';
import searchResult from './searchResult';
import topSearches from './topSearches';
import { StateExpertDirectoryType } from './types';

const reducer = combineReducers<StateExpertDirectoryType>({
  global,
  searchResult,
  createLead,
  industries,
  autocomplete,
  topSearches,
  searchRadius,
});

export default reducer;
