import { createAsyncThunk } from '@reduxjs/toolkit';
import { NextPageContext } from 'next';
import { StateType } from '../../../types';
import systemInformationAPI from '../api/systemInformation';

export const globalSystemInfoRequested = createAsyncThunk(
  'app/globalSystemInfoRequested',
  async ({ contextRequest }: { contextRequest?: NextPageContext['req'] }, store) => {
    const state = store.getState() as StateType;

    return systemInformationAPI(state).getGlobalSystemInfo({ contextRequest });
  },
);
