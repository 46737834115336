import { request } from '@pe-libs/react-components/build/lib/store/api';
import { NextPageContext } from 'next';
import { StateType } from '../../../types';
import { RadiusValues } from '../components/SearchSection/data';
import {
  autocompleteIndustriesResultSchema,
  autocompleteLocationsResultSchema,
  searchResultSchema,
  topSearchesSchema,
} from '../schemas/searchDirectory';

export type GetProfilesType = {
  contextRequest?: NextPageContext['req'];
  keyword: string;
  location: string;
  size: number;
  page: number;
  headers?: Record<string, string>;
  searchRadius: RadiusValues;
};

const searchAPI = (state: StateType) => {
  const locale = state.app.settings?.locale ?? 'de-DE';

  return {
    getProfiles: async ({ contextRequest, keyword, location, size, page, headers, searchRadius }: GetProfilesType) =>
      request({
        locale,
        contextRequest,
        path: `/search/profiles/?query=${encodeURIComponent(keyword)}&locationSearchTerm=${location}&page=${page -
          1}&size=${size}&radius=${searchRadius}`,
        method: 'GET',
        schema: searchResultSchema,
        withAuth: false,
        headers: headers && {
          'x-forwarded-host': headers.host,
          'user-agent': headers['user-agent'],
          'x-real-ip': headers['x-real-ip'],
          'x-forwarded-proto': headers['x-forwarded-proto'],
          'x-forwarded-for': headers['x-forwarded-for'],
        },
      }),
    getIndustries: async ({ contextRequest, search }: { contextRequest?: NextPageContext['req']; search: string }) =>
      request({
        locale,
        contextRequest,
        path: `/search/industries/autocomplete?query=${search}`,
        method: 'GET',
        schema: autocompleteIndustriesResultSchema,
        withAuth: false,
      }),
    getLocations: async ({ contextRequest, location }: { contextRequest?: NextPageContext['req']; location: string }) =>
      request({
        locale,
        contextRequest,
        path: `/search/locations/autocomplete?query=${location}`,
        method: 'GET',
        schema: autocompleteLocationsResultSchema,
        withAuth: false,
      }),
    getStatistics: async ({ contextRequest, size }: { contextRequest?: NextPageContext['req']; size: number }) =>
      request({
        locale,
        contextRequest,
        path: `/search/statistics/?size=${size}`,
        method: 'GET',
        schema: topSearchesSchema,
        withAuth: false,
      }),
  };
};

export default searchAPI;
