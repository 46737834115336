import { createAsyncThunk } from '@reduxjs/toolkit';
import { StateType } from '../../../types';
import searchAPI from '../api/search';

export const topSearchesRequested = createAsyncThunk(
  'EXPERT_DIRECTORY/TOP_SEARCHES_REQUESTED',
  (data: { size: number }, store) => {
    const state = store.getState() as StateType;

    return searchAPI(state).getStatistics(data);
  },
);
