import { createReducer } from '@reduxjs/toolkit';
import { onboardingFinished, registrationDataRequested, industries } from '../actions';
import { IndustryType, StateAuthOnboardingTypes } from './types';

const initialState = {
  loading: false,
  status: '',
  industries: [],
};

const onboardingReducer = createReducer<StateAuthOnboardingTypes>(initialState, (builder) =>
  builder
    .addCase(industries.fulfilled, (state, action) => {
      state.industries = action.payload.data as IndustryType[];
    })
    .addCase(onboardingFinished.pending, (state) => {
      state.loading = true;
    })
    .addCase(registrationDataRequested.fulfilled, (state, { payload }) => {
      state.registrationData = payload.data;
    })
    .addCase(onboardingFinished.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.status = payload.data.attributes.status;
    })
    .addCase(onboardingFinished.rejected, (state) => {
      state.loading = false;
      state.status = 'error';
    }),
);

export default onboardingReducer;
