import { regexEmail } from '@pe-libs/react-components/build/lib/helper';
import yup from '#schema/locale';

export const validationSchema = yup.object({
  consulting: yup.boolean(),
  newsletter: yup.boolean(),
  others: yup.boolean(),
  comment: yup.string().max(100),
  name: yup
    .string()
    .required()
    .min(3),
  surname: yup
    .string()
    .required()
    .min(3),
  email: yup
    .string()
    .required()
    .matches(regexEmail),
});

export const referralPostSchemaAttributes = yup.object({
  consulting: yup.boolean(),
  newsletter: yup.boolean().notRequired(),
  others: yup.boolean(),
  comment: yup.string(),
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  emailAddress: yup.string().required(),
  reviewId: yup.string().required(),
  profile: yup.string(),
  type: yup.string(),
  phone: yup.string(),
  invitationUrl: yup.string(),
});

export const referralPostSchema = yup.object({
  data: yup.object({
    attributes: referralPostSchemaAttributes,
  }),
});
