import { PeSelectOptionsProps } from '@pe-libs/react-components/build/components/PeSelect/types';

export enum RadiusValues {
  'Ganzer Ort' = '0',
  '+5 km' = '5',
  '+10 km' = '10',
  '+20 km' = '20',
  '+30 km' = '30',
  '+50 km' = '50',
  '+100 km' = '100',
  '+150 km' = '150',
  '+200 km' = '200',
}

const titlesRadiusEnum = Object.keys(RadiusValues);

const selectRadiusItems: PeSelectOptionsProps[] = [
  { labelId: '0', value: RadiusValues['Ganzer Ort'], title: titlesRadiusEnum[0] },
  { labelId: '1', value: RadiusValues['+5 km'], title: titlesRadiusEnum[1] },
  { labelId: '2', value: RadiusValues['+10 km'], title: titlesRadiusEnum[2] },
  { labelId: '3', value: RadiusValues['+20 km'], title: titlesRadiusEnum[3] },
  { labelId: '4', value: RadiusValues['+30 km'], title: titlesRadiusEnum[4] },
  { labelId: '5', value: RadiusValues['+50 km'], title: titlesRadiusEnum[5] },
  { labelId: '6', value: RadiusValues['+100 km'], title: titlesRadiusEnum[6] },
  { labelId: '7', value: RadiusValues['+150 km'], title: titlesRadiusEnum[7] },
  { labelId: '8', value: RadiusValues['+200 km'], title: titlesRadiusEnum[8] },
];

export default selectRadiusItems;
