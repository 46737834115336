import { createAsyncThunk } from '@reduxjs/toolkit';
import { StateType } from '../../../types';
import profilePublicAPI from '../api/profile';

export const profilePublicDetailsRequested = createAsyncThunk(
  'DEFAULT_LANDING_REFERRAL/PROFILE_PUBLIC_DETAILS_REQUESTED',
  async ({ profileId }: { profileId: string }, store) => {
    const state = store.getState() as StateType;

    return profilePublicAPI(state).getProfilePublic({ profileId });
  },
);
