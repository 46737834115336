import TagManager from 'react-gtm-module';

const GA_TRACKING_ID_GLOBAL = 'UA-39086589-1';
const GA_TRACKING_ID_ALL = 'UA-39086589-11';

export const pageView = (url: URL): void => {
  TagManager.dataLayer({
    dataLayer: [
      'config',
      GA_TRACKING_ID_GLOBAL,
      {
        page_path: url,
      },
    ],
  });

  TagManager.dataLayer({
    dataLayer: [
      'config',
      GA_TRACKING_ID_ALL,
      {
        page_path: url,
      },
    ],
  });
};
