import { createReducer } from '@reduxjs/toolkit';
import { topSearchesRequested } from '../actions';
import { StateTopSearchesTypes } from './types';

const initialState: StateTopSearchesTypes = {
  loading: false,
  data: [],
};

const topSearchesReducer = createReducer<StateTopSearchesTypes>(initialState, (builder) =>
  builder
    .addCase(topSearchesRequested.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data = payload.data;
    })
    .addCase(topSearchesRequested.rejected, (state) => {
      state.loading = false;
    })
    .addCase(topSearchesRequested.pending, (state) => {
      state.loading = true;
    }),
);

export default topSearchesReducer;
