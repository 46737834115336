import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { InferType } from 'yup';
import { StateType } from '../../../types';
import customerRequestsAPI from '../api/customerRequest';
import { CreateLeadSchema } from '../schemas/createLead';

export const leadFormOpened = createAction('EXPERT_DIRECTORY/LEAD_FORM_OPENED', () => ({ payload: {} }));

export const leadFormClosed = createAction('EXPERT_DIRECTORY/LEAD_FORM_CLOSED', () => ({ payload: {} }));

export const leadChangePageToNext = createAction('EXPERT_DIRECTORY/LEAD_CHANGE_PAGE_TO_NEXT', () => ({ payload: {} }));
export const leadChangePageBack = createAction('EXPERT_DIRECTORY/LEAD_CHANGE_PAGE_BACK', () => ({ payload: {} }));

export const leadCreated = createAsyncThunk(
  'EXPERT_DIRECTORY/LEAD_CREATED',
  (data: InferType<typeof CreateLeadSchema>, store) => {
    const state = store.getState() as StateType;

    return customerRequestsAPI(state).createLead(data);
  },
);
