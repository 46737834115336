import { createReducer } from '@reduxjs/toolkit';
import { industriesAutocompleted, searchInputCleared, locationsInputCleared, locationsAutocompleted } from '../actions';
import { StateAutocompleteType } from './types';

const initialState = {
  loading: false,
  industries: {
    meta: null,
    data: [],
  },
  locations: {
    meta: null,
    data: [],
  },
};

const industriesReducer = createReducer<StateAutocompleteType>(initialState, (builder) =>
  builder
    .addCase(industriesAutocompleted.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.industries = payload;
    })
    .addCase(industriesAutocompleted.rejected, (state) => {
      state.loading = false;
    })
    .addCase(searchInputCleared, (state) => {
      state.industries.meta = null;
      state.industries.data = [];
    })
    .addCase(industriesAutocompleted.pending, (state) => {
      state.loading = true;
    })
    .addCase(locationsAutocompleted.fulfilled, (state, { payload }) => {
      state.locations = payload;
    })
    .addCase(locationsInputCleared, (state) => {
      state.locations.meta = null;
      state.locations.data = [];
    }),
);

export default industriesReducer;
