import yup from '#schema/locale';
import { step1 } from './step1';
import { step2 } from './step2';

export const CreateLeadSchema = yup.object({
  page1: step1,
  page2: step2,
});

export const CreateLeadResponseSchema = yup.object({
  dueDate: yup.string().notRequired(),
  emailAddress: yup.string().notRequired(),
  fullName: yup.string().notRequired(),
  industry: yup.string().notRequired(),
  location: yup.string().notRequired(),
  message: yup.string().notRequired(),
  phoneNumber: yup.string().notRequired(),
  termsOfUse: yup.bool().notRequired(),
});

export const GetIndustriesSchema = yup.object({
  data: yup
    .array(
      yup.object({
        id: yup.string().required(),
        attributes: yup
          .object({
            _id: yup.string().required(),
            name: yup.string().required(),
            title: yup.string().required(),
          })
          .required(),
      }),
    )
    .required(),
});
