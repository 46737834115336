import yup from '#schema/locale';

export const externalRatingCrawlerCountSchema = yup.object({
  data: yup.array(
    yup.object({
      type: yup.string(),
      attributes: yup.object({
        count: yup.number(),
      }),
    }),
  ),
});
