import { request } from '@pe-libs/react-components/build/lib/store/api';
import { NextPageContext } from 'next/dist/shared/lib/utils';
import { StateType } from '../../../types';
import { referralPostSchemaAttributes } from '../schemas';

const formAPI = (state: StateType) => {
  const locale = state.app.settings?.locale ?? 'de-DE';

  return {
    getForm: async ({
      contextRequest,
      ...attributes
    }: {
      contextRequest?: NextPageContext['req'];
    } & Record<string, unknown>) =>
      request({
        locale,
        path: '/v2/referrals',
        method: 'POST',
        schema: referralPostSchemaAttributes,
        body: attributes,
        contextRequest,
        withAuth: false,
      }),
  };
};

export default formAPI;
