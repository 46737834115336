import yup from '../../../schema/locale';

export const globalSystemInfoSchema = yup.object({
  data: yup.object({
    attributes: yup.object({
      id: yup.string(),
      externalRatingCrawlerCount: yup.number(),
      industryTotalCount: yup.number(),
      profileTotalCount: yup.number(),
    }),
  }),
});
