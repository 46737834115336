import { request } from '@pe-libs/react-components/build/lib/store/api';
import { NextPageContext } from 'next';
import { StateType } from '../../../types';
import { industriesSchema } from '../schemas/onboarding';

const industriesAPI = (state: StateType) => {
  const locale = state.app?.settings?.locale ?? 'de-DE';

  return {
    getIndustries: async ({ contextRequest }: { contextRequest?: NextPageContext['req'] }) =>
      request({
        locale,
        contextRequest,
        path: '/v2/industries',
        method: 'GET',
        schema: industriesSchema,
        credentials: 'omit',
      }),
  };
};

export default industriesAPI;
