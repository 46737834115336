import { createAsyncThunk } from '@reduxjs/toolkit';
import { NextPageContext } from 'next';
import yup from '#schema/locale';
import { StateType } from '../../../types';
import accountsAPI from '../api/accounts';
import actionsAPI from '../api/actions';
import industriesAPI from '../api/industries';
import usersAPI from '../api/users';
import { trinityResponseSchema } from '../schemas/onboarding';

export const industries = createAsyncThunk(
  'auth/onboarding/load_industries',
  async (config: { contextRequest?: NextPageContext['req'] } | undefined, store) => {
    const state = store.getState() as StateType;

    return industriesAPI(state).getIndustries({ contextRequest: config?.contextRequest });
  },
);

export const onboardingFinished = createAsyncThunk(
  'auth/onboarding/finish_onboarding_requested',
  async (data: yup.InferType<typeof trinityResponseSchema> | { confirmationCode: string }, store) => {
    const state = store.getState() as StateType;

    return accountsAPI(state).getOnboardingFinished({ data });
  },
);

export const registrationDataRequestedFallback = createAsyncThunk(
  'auth/onboarding/registration_data_requested_fallback',
  async (confirmationCode: string, store) => {
    const state = store.getState() as StateType;

    return usersAPI(state).getRegistrationDataFallback({ confirmationCode });
  },
);

export const registrationDataRequested = createAsyncThunk(
  'auth/onboarding/registration_data_requested',
  async (confirmationCode: string, store) => {
    const state = store.getState() as StateType;

    return actionsAPI(state).getRegistrationData({ confirmationCode });
  },
);
