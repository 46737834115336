import { createAsyncThunk } from '@reduxjs/toolkit';
import { NextPageContext } from 'next';
import { StateType } from '../../../types';
import industriesAPI from '../api/industries';

export const industriesRequested = createAsyncThunk(
  'EXPERT_DIRECTORY/INDUSTRIES_REQUESTED',
  async (data: { contextRequest?: NextPageContext['req'] } | undefined, store) => {
    const state = store.getState() as StateType;

    return industriesAPI(state).getIndustries({ contextRequest: data?.contextRequest });
  },
);
