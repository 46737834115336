import yup from '#schema/locale';

export const profilePublicDetailsSchema = yup.object({
  loading: yup.boolean(),
  data: yup.object({
    attributes: yup.object({
      id: yup.string(),
      title: yup.string(),
      description: yup.string(),
      activities: yup.string(),
      offers: yup.string(),
    }),
  }),
});
