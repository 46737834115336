import { createReducer } from '@reduxjs/toolkit';
import { leadCreated, leadFormClosed, leadFormOpened, leadChangePageToNext, leadChangePageBack } from '../actions';
import { StateCreateLeadTypes } from './types';

const initialState = {
  loading: false,
  data: {},
  isModalOpen: false,
  currentPage: 'page1' as const,
};

const createLead = createReducer<StateCreateLeadTypes>(initialState, (builder) =>
  builder
    .addCase(leadCreated.pending, (state) => {
      state.loading = true;
    })
    .addCase(leadCreated.fulfilled, (state) => {
      state.loading = false;
      state.currentPage = 'successPage';
    })
    .addCase(leadCreated.rejected, (state) => {
      state.loading = false;
    })
    .addCase(leadFormClosed, (state) => {
      state.isModalOpen = false;
    })
    .addCase(leadFormOpened, (state) => {
      state.isModalOpen = true;
    })
    .addCase(leadChangePageToNext, (state) => {
      state.currentPage = 'page2';
    })
    .addCase(leadChangePageBack, (state) => {
      state.currentPage = 'page1';
    }),
);

export default createLead;
