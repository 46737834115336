import { request } from '@pe-libs/react-components/build/lib/store/api';
import { NextPageContext } from 'next/dist/shared/lib/utils';
import { StateType } from '../../../types';
import { profilePublicDetailsSchema } from '../schemas';

const profilePublicAPI = (state: StateType) => {
  const locale = state.app.settings?.locale ?? 'de-DE';

  return {
    getProfilePublic: async ({
      contextRequest,
      profileId,
    }: {
      contextRequest?: NextPageContext['req'];
      profileId: string;
    }) =>
      request({
        locale,
        path: `/v2/profiles/${profileId}/public`,
        method: 'GET',
        schema: profilePublicDetailsSchema,
        contextRequest,
        withAuth: false,
      }),
  };
};

export default profilePublicAPI;
