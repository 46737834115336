import { createAsyncThunk } from '@reduxjs/toolkit';
import { NextPageContext } from 'next';
import { StateType } from '../../../types';
import formAPI from '../api/form';

type FormData = {
  type: string;
  profile: string;
  consulting: boolean;
  newsletter: boolean;
  others: boolean;
  comment?: string;
  firstName: string;
  lastName: string;
  emailAddress: string;
  phoneNumber?: string;
  invitationUrl: string;
  reviewId: string;
  contextRequest?: NextPageContext['req'];
  offers?: string;
  description?: string;
};

export const referralFormRequested = createAsyncThunk(
  'DEFAULT_LANDING_REFERRAL/REFERRAL_FORM_REQUESTED',
  async (config: FormData, store) => {
    const state = store.getState() as StateType;
    const response = await formAPI(state).getForm(config);

    return response;
  },
);
