import { createReducer } from '@reduxjs/toolkit';
import { industriesRequested } from '../actions/industries';
import { StateIndustryType } from './types';

const initialState = {
  loading: false,
  items: [],
};

const industriesReducer = createReducer<StateIndustryType>(initialState, (builder) =>
  builder
    .addCase(industriesRequested.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.items = payload.data;
    })
    .addCase(industriesRequested.rejected, (state) => {
      state.loading = false;
    })
    .addCase(industriesRequested.pending, (state) => {
      state.loading = true;
    }),
);

export default industriesReducer;
