import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { StateType } from '../../../types';
import searchAPI, { GetProfilesType } from '../api/search';
import { RadiusValues } from '../components/SearchSection/data';

export const searchRequested = createAsyncThunk('EXPERT_DIRECTORY/SEARCH_REQUESTED', (data: GetProfilesType, store) => {
  const state = store.getState() as StateType;

  return searchAPI(state).getProfiles(data);
});

export const industriesAutocompleted = createAsyncThunk(
  'EXPERT_DIRECTORY/SEARCH_AUTOCOMPLETE_REQUESTED',
  (data: { search: string }, store) => {
    const state = store.getState() as StateType;

    return searchAPI(state).getIndustries(data);
  },
);

export const searchInputCleared = createAction('EXPERT_DIRECTORY/SEARCH_INPUT_CLEARED', () => ({ payload: {} }));

export const locationsAutocompleted = createAsyncThunk(
  'EXPERT_DIRECTORY/LOCATIONS_AUTOCOMPLETE_REQUESTED',
  (data: { location: string }, store) => {
    const state = store.getState() as StateType;

    return searchAPI(state).getLocations(data);
  },
);

export const locationsInputCleared = createAction('EXPERT_DIRECTORY/LOCATIONS_INPUT_CLEARED', () => ({ payload: {} }));

export const setSearchRadius = createAction('EXPERT_DIRECTORY/SEARCH_RADIUS_CHANGED', (radius: RadiusValues) => ({
  payload: radius,
}));
