import { createAsyncThunk } from '@reduxjs/toolkit';
import { StateType } from '../../../types';
import profileApi from '../api/profile';

export const getAffiliateUserData = createAsyncThunk(
  'AUTH/PROFILES/AFFILIATE_USER_DATA',
  async ({ profileId }: { profileId: string }, store) => {
    const state = store.getState() as StateType;

    return profileApi(state).getAffiliateUserData({ profileId });
  },
);
