/* eslint-disable camelcase */
import { createReducer } from '@reduxjs/toolkit';
import { searchRequested } from '../actions';
import { StateSearchResultTypes } from './types';

const initialState: StateSearchResultTypes = {
  loading: false,
  data: [],
  meta: {
    count: 0,
    page: 1,
    totalPages: 0,
  },
  context: {
    industry: {
      id: '',
      key: '',
      name: {
        de_DE: '',
        en_US: '',
      },
    },
    location: {
      name: '',
      id: '',
    },
  },
};
const searchResult = createReducer<StateSearchResultTypes>(initialState, (builder) =>
  builder
    .addCase(searchRequested.pending, (state) => {
      state.loading = true;
    })
    .addCase(searchRequested.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data = payload.data;
      state.meta = { ...payload.meta, page: (payload?.meta?.page ?? 0) + 1 };
      state.context = payload.context;
    })
    .addCase(searchRequested.rejected, (state) => {
      state.loading = false;
    }),
);

export default searchResult;
